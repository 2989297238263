import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Customer } from './Customer';
import { PurchaseOrderStore } from './PurchaseOrder';
import { SalesOrderLineStore } from './SalesOrderLine';
import { DistributionOrderStore } from './DistributionOrder';

export class SalesOrder extends Model {
    static backendResourceName = 'sales_order';
    static omitFields = [
        'deliveryIssue',
        'deliveryIssueCount',
        'minDeliveryDate',
        'maxDeliveryDate',
        'latestUnresolvedThreadMessage',
        'numberOfUnresolvedThreads',
    ];

    @observable id = null;
    @observable number = null;
    @observable deliveryDate = null;

    // Annotations
    @observable latestUnresolvedThreadMessage;
    @observable numberOfUnresolvedThreads;
    @observable deliveryIssue = null;
    @observable deliveryIssueCount = null;
    @observable minDeliveryDate = null;
    @observable maxDeliveryDate = null;

    relations() {
        return {
            customer: Customer,
            purchaseOrders: PurchaseOrderStore,
            lines: SalesOrderLineStore,
            distributionOrders: DistributionOrderStore,
        };
    }

    casts() {
        return {
            deliveryDate: Casts.date,
            minDeliveryDate: Casts.date,
            maxDeliveryDate: Casts.date,
        };
    }
}

export class SalesOrderStore extends Store {
    Model = SalesOrder;
    static backendResourceName = 'sales_order';
}
