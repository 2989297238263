import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { TransportCompany } from './TransportCompany';
import { TransportOrderLineStore } from './TransportOrderLine';
import { Thread } from 'store/Threads/Thread';

export const TRANSPORT_ORDER_STATUS_PLANNED = 'planned'
export const TRANSPORT_ORDER_STATUS_CONFIRMED = 'confirmed'
export const TRANSPORT_ORDER_STATUS_LOADED = 'loaded'
export const TRANSPORT_ORDER_STATUS_UNLOADED = 'unloaded'

export class TransportOrder extends Model {
    static backendResourceName = 'transport_order';
    static omitFields = [ 'latestUnresolvedThreadMessage', 'numberOfUnresolvedThreads'];


    @observable id = null;
    @observable loadDate = null;
    @observable unloadDate = null;
    @observable status = TRANSPORT_ORDER_STATUS_PLANNED

    @observable threads = this.relation(Thread);

    // Annotations
    @observable latestUnresolvedThreadMessage;
    @observable numberOfUnresolvedThreads;

    relations() {
        return {
            transportCompany: TransportCompany,
            lines: TransportOrderLineStore,
        };
    }

    casts() {
        return {
            loadDate: Casts.date,
            unloadDate: Casts.date,
        };
    }
}

export class TransportOrderStore extends Store {
    Model = TransportOrder;
    static backendResourceName = 'transport_order';
}
