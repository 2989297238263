import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { SalesOrderLineStore } from './SalesOrderLine';
import { PurchaseOrderStore } from './PurchaseOrder';
import { Supplier } from './Supplier';
import { Stock, StockStore } from './Stock';

export class Article extends Model {
    static backendResourceName = 'article';

    @observable id = null;
    @observable name = '';
    @observable productionTime = 0;
    @observable transportTime = 0;
    @observable fromStock = false;
    @observable minStockLevel = 0;
    @observable maxStockLevel = 0;
    @observable cbm = 0;

    relations() {
        return {
            supplier: Supplier,
            salesOrderLines: SalesOrderLineStore,
            purchaseOrderLines: PurchaseOrderStore,
            currentStock: Stock,
            projectedStock: Stock,
            stocks: StockStore,
        };
    }
}

export class ArticleStore extends Store {
    Model = Article;
    static backendResourceName = 'article';
}
