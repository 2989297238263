import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { DistributionOrder } from './DistributionOrder';
import { Article } from './Article';
import { Stock } from './Stock';

export class DistributionOrderLine extends Model {
    static backendResourceName = 'distribution_order_line';
    static omitFields = ['deliveryStock'];

    @observable id = null;
    @observable quantity = 0;

    relations() {
        return {
            distributionOrder: DistributionOrder,
            article: Article,
            deliveryStock: Stock,
        };
    }
}

export class DistributionOrderLineStore extends Store {
    Model = DistributionOrderLine;
    static backendResourceName = 'distribution_order_line';
}
