import { tint } from 'polished';

const primaryColor = '#091f2f';
const tintedPrimaryColor = tint(0.15, primaryColor);
const toolbarColor = '#dfe5eb';
const sidebarColor = '#eff2f5';
const buttonColor = '#48b4ca';
const dangerColor = '#dc0818';
const warningColor = '#ffc107';

export const theme = {
    primaryColor,
    tintedPrimaryColor,
    buttonColor,
    dangerColor,
    warningColor,
    toolbarColor,
    sidebarColor,
};
