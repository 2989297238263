export default {
    nav: {
        main: {
            assets: 'Master data',
            supplyChain: 'Supply Chain',
        },
        assets: {
            suppliers: 'Suppliers',
            articles: 'Articles',
            customers: 'Customers',
            transportCompanies: 'Transport Companies',
            users: 'Users',
            exactIntegrations: 'Exact Integrations',
            exactErrors: 'Exact Errors',
            globalValues: 'Settings',
            globalFiles: 'Special files',
            emailTemplates: 'Email templates',
        },
        audit: {
            accessLog: 'Access Log',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog'
        },
        supplyChain: {
            salesOrders: 'Sales Orders',
            purchaseOrders: 'Purchase Orders',
            transportOrders: 'Transport Orders',
            articles: 'Articles',
        },
    },
    user: {
        overview: {
            title: 'Users',
            addButton: 'Add User',
        },
        create: {
            title: 'Create User',
        },
        edit: {
            title: 'Edit User',
        },
        field: {
            fullName: { label: 'Name' },
            firstName: { label: 'First Name' },
            lastName: { label: 'Last Name' },
            email: { label: 'Email' },
            password: { label: 'Password' },
            dateJoined: { label: 'Added At' },
            groups: {
                label: 'Groups',
                value: {
                    superuser: 'Superuser',
                    admin: 'Admin',
                    manager: 'Manager',
                    purchaser: 'Purchaser',
                    planner: 'Planner',
                    supplier: 'Supplier',
                    distributor: 'Distributor',
                },
                empty: 'No groups assigned',
            },
            supplier: {
                label: 'Supplier',
                none: 'No supplier assigned',
            },
            language: {
                label: 'Language'
            },
            isActive: {
                label: 'Active',
            },
        },
        login: {
            title: 'Login',
            forgotPasswordLink: 'Forgot password?',
            errors: {
                invalidCredentials: 'Username or password do not match',
                unknown: 'Unknown error, status code: {{status}}',
            },
            loginButton: 'Log In',
        },
        passwordForgot: {
            title: 'Forgot password',
            requestButton: 'Send Email',
            requestedEmailText:
                'An email with a reset code has been sent to this email address.',
            loggedInError: 'You are logged in. Password forgot only works when you are not logged in.',
        },
        resetPassword: {
            title: 'Reset password',
            resetButton: 'Reset',
            saveSuccess: 'Password is successfully reset.',
            loggedInError: 'You are logged in. Password reset only works when you are not logged in.',
        },
        changePassword: {
            title: 'Change Password',
            field: {
                passwordOld: {
                    label: 'Current password',
                },
                passwordNew: {
                    label: 'New password',
                },
            },
            saveButton: 'Save',
        },
        account: {
            title: 'Account Details',
            logoutButton: 'Log Out',
            info: {
                title: 'Account Info',
            },
            password: {
                title: 'Change Password',
            },
            logout: {
                title: 'Log Out',
                confirm: 'Are you sure you want to logout?'
            },
        },
    },
    myFilter: {
        custom: 'Custom',
        action: {
            unsetDefault: 'Unset Default',
            setDefault: 'Set Default',
            setCurrent: 'Save Current Filters',
            delete: 'Delete',
        },
    },
    globalValue: {
        edit: {
            title: 'Edit value of global setting',
        },
        overview: {
            title: 'Global settings',
        },
        field: {
            key: {
                label: 'Setting name',
            },
            value: {
                label: 'Value',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Edit special file',
        },
        overview: {
            title: 'Special files',
        },
        field: {
            key: {
                label: 'Name for file',
            },
            value: {
                label: 'File',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Audit log',
        },
        field: {
            originalUser: {
                label: 'Original user',
            },
            masqueradingAsUser: {
                label: 'Masquerading as user',
            },
            ipAddress: {
                label: 'IP address',
            },
            method: {
                label: 'HTTP method',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Status code',
            },
            additionalInfo: {
                label: 'Extra information',
            },
            requestTime: {
                label: 'Time of access',
            },
        },
    },
    tooltips: {
        masquerade: 'Masquerade',
        edit: 'Edit',
        delete: 'Delete',
        view: 'View',
        restore:'Restore',
    },
    form: {
        repeat: 'Repeat',
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Other',
            none: 'No file uploaded yet',
        },
        saveButton: 'Save',
        multiPick: {
            searchPlaceholder: 'Select…',
            selectedText: '$1 of $2 selected',
            noneSelectedText: 'None selected',
            selectAllButton: 'All',
            selectNoneButton: 'None',
        },
        notifications: {
            saveSuccess: 'Saved successfully',
            deleteSuccess: 'Deleted successfully',
            saveError: 'Error with saving ({{status}})',
            saveValError: 'Not all information is filled in correctly',
            saveAuthError: 'Invalid credentials',
            newAppVersion:
                'New version available, click to reload page as soon as possible',
        },
        submitButton: 'Submit',
        cancelButton: 'Cancel',
        addButton: 'Add',
        startDate: 'Start Date',
        endDate: 'End Date',
        deleteConfirmation: 'Are you sure you want to delete this entity?',
        restoreConfirmation: 'Are you sure you want to restore this entity?',
        yes: 'Yes',
        either: 'Either',
        no: 'No',
    },
    crash: {
        heading: 'Application crashed',
        subHeading1: 'We’re very sorry, but the application has crashed.',
        subHeading2: 'Our team has been notified.',
        reload: 'Reload page'
    },
    emailTemplate: {
        overview: {
            title: 'Email templates',
            addButton: 'Add email template',
        },
        create: { title: 'Create mail template' },
        edit: { title: 'Edit mail template' },
        add: { title: 'Add mail template' },
        field: {
            id: { label: 'ID' },
            isDefault: { label: 'Is default' },
            name: { label: 'Name' },
            emailType: { label: 'Email type' },
            subject: { label: 'Subject' },
            textContent: { label: 'Text variant of email',  placeholder: 'Some mail clients only support text' },
            senderEmail: { label: 'Sender email' },
        }
    },
    emailTypes: {
        password_reset: 'Password reset',
        user_activation: 'User activation',
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        weekDay: {
            monday: 'Mo',
            tuesday: 'Tu',
            wednesday: 'We',
            thursday: 'Th',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'Su',
        },
        month: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
    },
    pageNotFoundMessage: 'Page not found',
    brandingMessage: 'Powered by Code Yellow',
    purchaseOrder: {
        overview: {
            title: 'Purchase Orders',
            acceptButton: 'Accept',
            rejectButton: 'Reject',
            addLine: 'Add Line',
        },
        field: {
            supplier: { label: 'Supplier' },
            productionDate: { label: 'Production' },
            loadDate: { label: 'Load' },
            unloadDate: { label: 'Unload' },
            readyStatus: {
                label: 'Ready Status',
                value: {
                    '0_pending': 'Pending',
                    '1_open': 'Open',
                    '2_partial': 'Partial',
                    '3_complete': 'Complete',
                },
            },
            notes: { label: 'Notes' },
            threads: { label: 'Note contains' },
            unresolvedThreads: { label: 'Has' }
        },
    },
    purchaseOrderLine: {
        field: {
            article: { label: 'Article' },
            quantity: { label: 'Quantity' },
            ready: { label: 'Ready' },
        },
    },
    salesOrder: {
        overview: {
            title: 'Sales Orders',
            bulkCreatePurchaseOrders: 'Create Purchase Orders',
            short: '{{count}} short',
            delayIssuePartially: 'Delay Partially',
            delayIssueFully: 'Delay Fully',
            noIssues: 'There are currently no delivery issues.',
        },
        field: {
            customer: { label: 'Customer' },
            deliveryDate: { label: 'Delivery' },
            notes: { label: 'Notes' },
            deliveryIssue: { label: 'Delivery Issue' },
        },
    },
    salesOrderLine: {
        field: {
            article: { label: 'Article' },
            quantity: { label: 'Quantity' },
        },
    },
    article: {
        overview: {
            title: 'Articles',
            orderModal: {
                title: 'Add to Basket: {{article}}',
            },
            addToBasket: 'Add to Basket',
            addDemandToBasket: 'Add Demand to Basket',
            bulkAddToBasket: 'Add to Basket ({{count}})',
            bulkAddDemandToBasket: 'Add Demand to Basket ({{count}})',
            shoppingBasket: {
                empty: 'There is nothing in your shopping basket yet.',
                purchaseButton: 'Purchase',
                discard_one: 'Leaving this page will discard your shopping basket containing {{count}} item, are you sure you want to leave?',
                discard_other: 'Leaving this page will discard your shopping basket containing {{count}} items, are you sure you want to leave?',
            },
        },
        create: { title: 'Add Article' },
        edit: { title: 'Edit Article' },
        field: {
            name: { label: 'Name' },
            supplier: { label: 'Supplier' },
            suppliers: { label: 'Suppliers' },
            productionTime: { label: 'Production Time (d)' },
            transportTime: { label: 'Transport Time (d)' },
            fromStock: { label: 'From DC Stock' },
            minStockLevel: { label: 'Min Stock' },
            maxStockLevel: { label: 'Max Stock' },
            currentStock: { label: 'Current Stock' },
            projectedStock: { label: 'Projected Stock' },
            demand: { label: 'Demand' },
        },
    },
    supplier: {
        overview: { title: 'Suppliers' },
        create: { title: 'Add Supplier' },
        edit: { title: 'Edit Supplier' },
        field: {
            name: { label: 'Name' },
            emailAddress: { label: 'Email Address' },
            phoneNumber: { label: 'Phone Number' },
            address: { label: 'Address' },
            street: { label: 'Street' },
            number: { label: 'Number' },
            numberSuffix: { label: 'Suffix' },
            zipCode: { label: 'ZIP Code' },
            city: { label: 'City' },
            country: { label: 'Country' },
        },
    },
    customer: {
        overview: { title: 'Customers' },
        create: { title: 'Add Customer' },
        edit: { title: 'Edit Customer' },
        field: {
            name: { label: 'Name' },
            emailAddress: { label: 'Email Address' },
            phoneNumber: { label: 'Phone Number' },
            address: { label: 'Address' },
            street: { label: 'Street' },
            number: { label: 'Number' },
            numberSuffix: { label: 'Suffix' },
            zipCode: { label: 'ZIP Code' },
            city: { label: 'City' },
            country: { label: 'Country' },
        },
    },
    exactIntegration: {
        overview: {
            title: 'Exact Integrations',
            activateButton: 'Activate',
            activateResult: {
                ok: 'Exact activated succesfully',
                error: 'Exact activation error: {{error}}',
            },
        },
        create: { title: 'Add Exact Integration' },
        edit: { title: 'Edit Exact Integration' },
        field: {
            id: { label: 'ID' },
            country: {
                label: 'Country',
                value: {
                    nl: 'NL',
                    be: 'BE',
                    de: 'DE',
                },
            },
            errorCount: { label: '# Errors' },
            division: { label: 'Division' },
            active: { label: 'Active' },
        },
    },
    exactError: {
        overview: {
            title: 'Exact Errors',
            topicHeader: {
                account_one: 'Account ({{count}})',
                account_other: 'Accounts ({{count}})',
                item_one: 'Item ({{count}})',
                item_other: 'Items ({{count}})',
                supplier_item_one: 'Supplier Item ({{count}})',
                supplier_item_other: 'Supplier Items ({{count}})',
                sales_order_one: 'Sales Order ({{count}})',
                sales_order_other: 'Sales Orders ({{count}})',
                sales_order_line_one: 'Sales Order Line ({{count}})',
                sales_order_line_other: 'Sales Order Lines ({{count}})',
                purchase_order_one: 'Purchase Order ({{count}})',
                purchase_order_other: 'Purchase Orders ({{count}})',
                purchase_order_line_one: 'Purchase Order Line ({{count}})',
                purchase_order_line_other: 'Purchase Order Lines ({{count}})',
            },
        },
        field: {
            integration: { label: 'Integration' },
            topic: {
                label: 'Topic',
                value: {
                    account: 'Account',
                    item: 'Item',
                    supplier_item: 'Supplier Item',
                    sales_order: 'Sales Order',
                    sales_order_line: 'Sales Order Line',
                    purchase_order: 'Purchase Order',
                    purchase_order_line: 'Purchase Order Line',
                },
            },
            status: {
                label: 'Status',
                value: {
                    create_error: 'Create Error',
                    update_error: 'Update Error',
                    delete_error: 'Delete Error',
                },
            },
            error: { label: 'Error' },
            payloadCount: { label: '# Occurrences' },
            blockingCount: { label: '# Blocking' },
        },
    },
    exactPayload: {
        field: {
            exactId: { label: 'Exact ID' },
        },
    },
    json: {
        entries_one: '{{count}} entry',
        entries_other: '{{count}} entries',
    },
    transportCompany: {
        overview: { title: 'Transport Companies' },
        create: { title: 'Add Transport Company' },
        edit: { title: 'Edit Transport Company' },
        field: {
            name: { label: 'Name' },
            email: { label: 'Email' },
        },
    },
    stock: {
        overview: {
            title: 'Stock',
            date: 'Date',
        },
        field: {
            article: { label: 'Article' },
            startDate: { label: 'Start Date' },
            endDate: { label: 'End Date' },
            inProduction: { label: 'In Production' },
            toTransport: { label: 'To Transport' },
            inTransport: { label: 'In Transport' },
            toDistribute: { label: 'To Distribute' },
        },
    },
    distributionOrder: {
        field: {
            deliveryDate: { label: 'Delivery' },
        },
    },
    distributionOrderLine: {
        field: {
            article: { label: 'Article' },
            quantity: { label: 'Quantity' },
            deliveryIssue: {
                label: 'Delivery Issue',
                value: '{{count}} until {{date}}',
            },
        },
    },
    transportOrder: {
        overview: {
            title: 'Transport Orders',
            addTransportOrderButton: 'Add Transport Order',
            addTransportOrderModal: { title: 'Add Transport Order' },
            date: 'Date',
            open: {
                totalCbm: '{{value}} Total',
                remainingCbm: '{{value}} Remaining',
                transportButton: 'Transport',
                empty: 'There is nothing that needs to be transported.',
            },
        },
        field: {
            transportCompany: { label: 'Transport Company' },
            loadDate: { label: 'Load' },
            unloadDate: { label: 'Unload' },
            totalCbm: { label: 'Total CBM' },
            notes: { label: 'Notes' },
        },
    },
    productionBatch: {
        field: {
            quantity: { label: 'Quantity' },
            readyDate: { label: 'Ready' },
        },
    },
    thread: {
        actions: {
            reply: 'Reply',
            resolve: 'Resolve',
            resolveAndReply: 'Reply and unresolve',
            hideComments: 'Hide comments',
            showComments: 'Show {{count}} comments',
        },
        field: {
            reply: { label: 'Reply...' },
            message: { label: 'New note...' },
            notes: {
                more: '{{count}} more',
                none: 'Add a note...',
            }
        },
        button: {
            showResolved: 'Show resolved notes...',
            hideResolved: 'Hide resolved notes...',
            hasUnresolved: 'Resolved notes',
            hasResolved: 'Unresolved notes',
        }
    },
    transportOrderLine: {
        field: {
            quantity: { label: 'Quantity' },
            productionBatch: { label: 'Ready' },
        },
    },
};
