import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { PurchaseOrder } from './PurchaseOrder';
import { Article } from './Article';
import { ProductionBatchStore } from './ProductionBatch';

export class PurchaseOrderLine extends Model {
    static backendResourceName = 'purchase_order_line';
    static omitFields = ['ready', 'readyStatus'];

    @observable id = null;
    @observable quantity = 0;
    @observable ready = null;
    @observable readyStatus = null;

    relations() {
        return {
            purchaseOrder: PurchaseOrder,
            article: Article,
            productionBatches: ProductionBatchStore,
        };
    }
}

export class PurchaseOrderLineStore extends Store {
    Model = PurchaseOrderLine;
    static backendResourceName = 'purchase_order_line';
}
