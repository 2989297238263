import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { SalesOrder } from './SalesOrder';
import { DistributionOrderLineStore } from './DistributionOrderLine';

export class DistributionOrder extends Model {
    static backendResourceName = 'distribution_order';
    static omitFields = ['deliveryIssue'];

    @observable id = null;
    @observable deliveryDate = null;
    @observable deliveryIssue = null;

    relations() {
        return {
            salesOrder: SalesOrder,
            lines: DistributionOrderLineStore,
        };
    }

    casts() {
        return {
            deliveryDate: Casts.date,
        }
    }
}

export class DistributionOrderStore extends Store {
    Model = DistributionOrder;
    static backendResourceName = 'distribution_order';
}
