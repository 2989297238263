import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { SalesOrder } from './SalesOrder';
import { Supplier } from './Supplier';
import { PurchaseOrderLineStore } from './PurchaseOrderLine';
import { Thread, ThreadStore } from 'store/Threads/Thread';

export class PurchaseOrder extends Model {
    static backendResourceName = 'purchase_order';
    static omitFields = [
        'readyStatus',
        'minLoadDate',
        'maxLoadDate',
        'minUnloadDate',
        'maxUnloadDate',
        'latestUnresolvedThreadMessage',
        'numberOfUnresolvedThreads',
    ];

    @observable id = null;
    @observable accepted = false;
    @observable productionDate = null;
    @observable loadDate = null;
    @observable unloadDate = null;

    @observable readyStatus = null;
    @observable minLoadDate = null;
    @observable maxLoadDate = null;
    @observable minUnloadDate = null;
    @observable maxUnloadDate = null;
    @observable latestUnresolvedThreadMessage = null;
    @observable numberOfUnresolvedThreads = null;

    get noteThread() {
        return this.threads.length > 0 ? this.threads.at(0) : new Thread({ purchaseOrder: { id: this.id } }, { relations: ['notes', 'purchaseOrder'] })
    }

    relations() {
        return {
            salesOrder: SalesOrder,
            supplier: Supplier,
            lines: PurchaseOrderLineStore,
            threads: ThreadStore
        };
    }

    casts() {
        return {
            productionDate: Casts.date,
            loadDate: Casts.date,
            unloadDate: Casts.date,
            minLoadDate: Casts.date,
            maxLoadDate: Casts.date,
            minUnloadDate: Casts.date,
            maxUnloadDate: Casts.date,
        }
    }
}

export class PurchaseOrderStore extends Store {
    Model = PurchaseOrder;
    static backendResourceName = 'purchase_order';
}
