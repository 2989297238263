export default {
    nav: {
        main: {
            assets: 'Stamgegevens',
            supplyChain: 'Supply Chain',
        },
        assets: {
            suppliers: 'Leverancies',
            articles: 'Artikelen',
            customers: 'Klanten',
            transportCompanies: 'Transportbedrijven',
            users: 'Gebruikers',
            exactIntegrations: 'Exact Integraties',
            exactErrors: 'Exact Foutmeldingen',
            globalValues: 'Instellingen',
            globalFiles: 'Bijzondere bestanden',
            emailTemplates: 'Email sjablonen',
        },
        audit: {
            accessLog: 'Toegangslog',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog'
        },
        supplyChain: {
            salesOrders: 'Verkooporders',
            purchaseOrders: 'Inkooporders',
            transportOrders: 'Transportorders',
            articles: 'Artikelen',
        },
    },
    user: {
        overview: {
            title: 'Gebruikers',
            addButton: 'Nieuwe Gebruiker',
        },
        create: {
            title: 'Gebruiker Aanmaken',
        },
        edit: {
            title: 'Gebruiker Aanpassen',
        },
        field: {
            fullName: { label: 'Naam' },
            firstName: { label: 'Voornaam' },
            lastName: { label: 'Achternaam' },
            email: { label: 'E-mail' },
            language: { label: 'Taal' },
            password: { label: 'Wachtwoord' },
            dateJoined: { label: 'Toegevoegd Op' },
            groups: {
                label: 'Groepen',
                value: {
                    superuser: 'Supergebruiker',
                    admin: 'Administrator',
                    manager: 'Manager',
                    purchaser: 'Inkoper',
                    planner: 'Planner',
                    supplier: 'Leverancier',
                    distributor: 'Distributeur',
                },
                empty: 'Geen groepen toegekend',
            },
            supplier: {
                label: 'Leverancier',
                none: 'Geen leverancier toegekend',
            },
            isActive: {
                label: 'Actief',
            },
        },
        login: {
            title: 'Inloggen',
            forgotPasswordLink: 'Wachtwoord vergeten?',
            errors: {
                invalidCredentials: 'E-mail of wachtwoord is niet correct',
                unknown: 'Onbekende fout, status code: {{status}}', },
            loginButton: 'Inloggen',
        },
        passwordForgot: {
            title: 'Wachtwoord Vergeten',
            requestButton: 'Verstuur Email',
            requestedEmailText:
                'Er is een email met een wachtwoord reset code verzonden naar uw email adres.',
            loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord vergeten aanvragen als je uitgelogd bent.',
        },
        resetPassword: {
            title: 'Wachtwoord Reset',
            resetButton: 'Reset',
            saveSuccess: 'Wachtwoord is succesvol gereset',
            loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord resetten als je uitgelogd bent.',
        },
        changePassword: {
            title: 'Wachtwoord Aanpassen',
            field: {
                passwordOld: {
                    label: 'Huidig wachtwoord',
                },
                passwordNew: {
                    label: 'Nieuw wachtwoord',
                },
            },
            saveButton: 'Opslaan',
        },
        account: {
            title: 'Profiel',
            logoutButton: 'Uitloggen',
            info: {
                title: 'Gegevens',
            },
            password: {
                title: 'Wachtwoord Veranderen',
            },
            logout: {
                title: 'Uitloggen',
                confirm: 'Weet u zeker dat u wilt uitloggen?'
            },
        },
    },
    globalValue: {
        edit: {
            title: 'Waarde wijzigen van algemene instelling',
        },
        overview: {
            title: 'Algemene instellingen',
        },
        field: {
            key: {
                label: 'Naam instelling',
            },
            value: {
                label: 'Waarde',
            },
            writable: {
                label: 'Schrijfbaar?',
            },
            readable: {
                label: 'Leesbaar?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Bijzonder bestand bewerken',
        },
        overview: {
            title: 'Bijzondere bestanden',
        },
        field: {
            key: {
                label: 'Naam voor bestand',
            },
            value: {
                label: 'Bestand',
            },
            writable: {
                label: 'Schrijfbaar?',
            },
            readable: {
                label: 'Leesbaar?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Auditlog',
        },
        field: {
            originalUser: {
                label: 'Oorspronkelijke gebruiker',
            },
            masqueradingAsUser: {
                label: 'Gemaskeerd als gebruiker',
            },
            ipAddress: {
                label: 'IP-adres',
            },
            method: {
                label: 'HTTP-methode',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Statuscode',
            },
            additionalInfo: {
                label: 'Extra informatie',
            },
            requestTime: {
                label: 'Tijdstip van toegang',
            },
        },
    },
    myFilter: {
        custom: 'Nieuw',
        action: {
            unsetDefault: 'Gebruik niet als standaard',
            setDefault: 'Gebruik als standaard',
            setCurrent: 'Sla huidige filters op',
            delete: 'Verwijderen',
        },
    },
    tooltips: {
        masquerade: 'Voordoen als',
        edit: 'Aanpassen',
        delete: 'Verwijderen',
        view: 'Bekijk',
        restore:'Herstel',
    },
    form: {
        repeat: 'Herhaal',
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Anders',
            none: 'Nog geen bestand geüpload',
        },
        saveButton: 'Opslaan',
        multiPick: {
            searchPlaceholder: 'Selecteer…',
            selectedText: '$1 uit $2 geselecteerd',
            noneSelectedText: 'Geen geselecteerd',
            selectAllButton: 'Alle',
            selectNoneButton: 'Geen',
        },
        notifications: {
            saveSuccess: 'Succesvol opgeslagen',
            deleteSuccess: 'Succesvol verwijderd',
            saveError: 'Fout met opslaan ({{status}})',
            saveValError: 'Niet alle informatie is correct ingevuld',
            saveAuthError: 'Niet geauthorizeerd',
            newAppVersion:
                'Nieuwe versie beschikbaar, klik om de pagina te verversen',
        },
        submitButton: 'Verstuur',
        cancelButton: 'Annuleren',
        addButton: 'Toevoegen',
        deleteConfirmation: 'Weet u zeker dat u dit wilt verwijderen?',
        restoreConfirmation: 'Weet u zeker dat u dit wilt herstellen?',
        startDate: 'Start Datum',
        endDate: 'Eind Datum',
        yes: 'Ja',
        no: 'Nee',
        either: 'Beide',
    },
    crash: {
        heading: 'Applicatie gecrasht',
        subHeading1: 'Onze excuses, maar de applicatie is gecrasht.',
        subHeading2: 'Ons team is op de hoogte gesteld.',
        reload: 'Herlaad pagina'
    },
    emailTemplate: {
        overview: {
            title: 'Email sjablonen' ,
            addButton: 'Nieuw sjabloon',
        },
        create: { title: 'Mail sjabloon aanmaken' },
        edit: { title: 'Mail sjabloon aanpassen' },
        add: { title: 'Mail sjabloon toevoegen' },
        field: {
            id: { label: 'ID' },
            isDefault: { label: 'Is standaard' },
            name: { label: 'Naam' },
            emailType: { label: 'Email type' },
            subject: { label: 'Onderwerp' },
            textContent: { label: 'Text variant van email', placeholder: 'Sommige mail clients supporten alleen tekst' },
            senderEmail: { label: 'Verzender' },
        }
    },
    emailTypes: {
        password_reset: 'Wachtwoord reset',
        user_activation: 'Account activatie',
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        weekDay: {
            monday: 'Ma',
            tuesday: 'Di',
            wednesday: 'Wo',
            thursday: 'Do',
            friday: 'Vr',
            saturday: 'Za',
            sunday: 'Zo',
        },
        month: {
            january: 'Januari',
            february: 'Februari',
            march: 'Maart',
            april: 'April',
            may: 'Mei',
            june: 'Juni',
            july: 'Juli',
            august: 'Augustus',
            september: 'September',
            october: 'Oktober',
            november: 'November',
            december: 'December',
        },
    },
    pageNotFoundMessage: 'Pagina niet gevonden',
    brandingMessage: 'Powered by Code Yellow',
    purchaseOrder: {
        overview: {
            title: 'Inkooporders',
            acceptButton: 'Accepteren',
            rejectButton: 'Afwijzen',
            addLine: 'Regel Toevoegen',
        },
        field: {
            supplier: { label: 'Leverancier' },
            productionDate: { label: 'Productie' },
            loadDate: { label: 'Laden' },
            unloadDate: { label: 'Lossen' },
            readyStatus: {
                label: 'Gereedstatus',
                value: {
                    '0_pending': 'In Afwachting',
                    '1_open': 'Open',
                    '2_partial': 'Gedeeltelijk',
                    '3_complete': 'Volledig',
                },
            },
            notes: { label: 'Notities' },
            threads: { label: 'Notitie bevat' },
            unresolvedThreads: { label: 'Heeft' },
        },
    },
    purchaseOrderLine: {
        field: {
            article: { label: 'Artikel' },
            quantity: { label: 'Aantal' },
            ready: { label: 'Gereed' },
        },
    },
    salesOrder: {
        overview: {
            title: 'Verkooporders',
            bulkCreatePurchaseOrders: 'Inkooporders Aanmaken',
            short: '{{count}} tekort',
            delayIssuePartially: 'Vertraag Gedeeltelijk',
            delayIssueFully: 'Vertraag Volledig',
            noIssues: 'Er zijn momenteel geen leveringsproblemen.',
        },
        field: {
            customer: { label: 'Klant' },
            deliveryDate: { label: 'Levering' },
            notes: { label: 'Notities' },
            deliveryIssue: { label: 'Leveringsprobleem' },
        },
    },
    salesOrderLine: {
        field: {
            article: { label: 'Artikel' },
            quantity: { label: 'Aantal' },
        },
    },
    article: {
        overview: {
            title: 'Artikelen',
            orderModal: {
                title: 'Order naar Voorraad',
            },
            addToBasket: 'Voeg toe aan Winkelmand',
            addDemandToBasket: 'Voeg Vraag toe aan Winkelmand',
            bulkAddToBasket: 'Voeg toe aan Winkelmand ({{count}})',
            bulkAddDemandToBasket: 'Voeg Vraag toe aan Winkelmand ({{count}})',
            shoppingBasket: {
                empty: 'Er zit nog niks in je winkelmand.',
                purchaseButton: 'Inkopen',
                discard_one: 'Als je deze pagina verlaat verlies je je winkelmand met {{count}} artikel, weet je zeker dat je de pagina wilt verlaten?',
                discard_other: 'Als je deze pagina verlaat verlies je je winkelmand met {{count}} artikelen, weet je zeker dat je de pagina wilt verlaten?',
            },
        },
        create: { title: 'Artikel Aanmaken' },
        edit: { title: 'Artikel Aanpassen' },
        field: {
            name: { label: 'Naam' },
            supplier: { label: 'Leverancier' },
            suppliers: { label: 'Leveranciers' },
            productionTime: { label: 'Productietijd (d)' },
            transportTime: { label: 'Transporttijd (d)' },
            fromStock: { label: 'Uit DC Voorraad' },
            minStockLevel: { label: 'Min Voorraad' },
            maxStockLevel: { label: 'Max Voorraad' },
            currentStock: { label: 'Huidige Voorraad' },
            projectedStock: { label: 'Verwachte Voorraad' },
            demand: { label: 'Vraag' },
        },
    },
    supplier: {
        overview: { title: 'Leveranciers' },
        create: { title: 'Leverancier Aanmaken' },
        edit: { title: 'Leverancier Aanpassen' },
        field: {
            name: { label: 'Naam' },
            emailAddress: { label: 'Emailadres' },
            phoneNumber: { label: 'Telefoonnummer' },
            address: { label: 'Adres' },
            street: { label: 'Straat' },
            number: { label: 'Nummer' },
            numberSuffix: { label: 'Toevoeging' },
            zipCode: { label: 'Postcode' },
            city: { label: 'Plaats' },
            country: { label: 'Land' },
        },
    },
    customer: {
        overview: { title: 'Klanten' },
        create: { title: 'Klant Aanmaken' },
        edit: { title: 'Klant Aanpassen' },
        field: {
            name: { label: 'Naam' },
            emailAddress: { label: 'Emailadres' },
            phoneNumber: { label: 'Telefoonnummer' },
            address: { label: 'Adres' },
            street: { label: 'Straat' },
            number: { label: 'Nummer' },
            numberSuffix: { label: 'Toevoeging' },
            zipCode: { label: 'Postcode' },
            city: { label: 'Plaats' },
            country: { label: 'Land' },
        },
    },
    exactIntegration: {
        overview: {
            title: 'Exact Integraties',
            activateButton: 'Activeren',
            activateResult: {
                ok: 'Exact is succesvol geactiveerd',
                error: 'Exact activatiefoutmelding: {{error}}',
            },
        },
        create: { title: 'Exact Integratie Aanmaken' },
        edit: { title: 'Exact Integratie Aanpassen' },
        field: {
            id: { label: 'ID' },
            country: {
                label: 'Land',
                value: {
                    nl: 'NL',
                    be: 'BE',
                    de: 'DE',
                },
            },
            errorCount: { label: '# Foutmeldingen' },
            division: { label: 'Divisie' },
            active: { label: 'Actief' },
        },
    },
    exactError: {
        overview: {
            title: 'Exact Foutmeldingen',
            topicHeader: {
                account_one: 'Relatie ({{count}})',
                account_other: 'Relaties ({{count}})',
                item_one: 'Artikel ({{count}})',
                item_other: 'Artikel ({{count}})',
                supplier_item_one: 'Artikelleverancier ({{count}})',
                supplier_item_other: 'Artikelleveranciers ({{count}})',
                sales_order_one: 'Verkooporder ({{count}})',
                sales_order_other: 'Verkooporders ({{count}})',
                sales_order_line_one: 'Verkooporderregel ({{count}})',
                sales_order_line_other: 'Verkooporderregels ({{count}})',
                purchase_order_one: 'Inkooporder ({{count}})',
                purchase_order_other: 'Inkooporders ({{count}})',
                purchase_order_line_one: 'Inkooporderregel ({{count}})',
                purchase_order_line_other: 'Inkooporderregels ({{count}})',
            },
        },
        field: {
            integration: { label: 'Integratie' },
            topic: {
                label: 'Onderwerp',
                value: {
                    account: 'Relatie',
                    item: 'Artikel',
                    supplier_item: 'Artikelleverancier',
                    sales_order: 'Verkooporder',
                    sales_order_line: 'Verkooporderregel',
                    purchase_order: 'Inkooporder',
                    purchase_order_line: 'Inkooporderregel',
                },
            },
            status: {
                label: 'Status',
                value: {
                    create_error: 'Aanmaakfoutmelding',
                    update_error: 'Aanpasfoutmelding',
                    delete_error: 'Verwijderfoutmelding',
                },
            },
            error: { label: 'Foutmelding' },
            payloadCount: { label: '# Gebeurtenissen' },
            blockingCount: { label: '# Geblokkeerd' },
        },
    },
    exactPayload: {
        field: {
            exactId: { label: 'Exact ID' },
        },
    },
    json: {
        entries_one: '{{count}} regel',
        entries_other: '{{count}} regels',
    },
    transportCompany: {
        overview: { title: 'Transportbedrijven' },
        create: { title: 'Transportbedrijf Aanpassen' },
        edit: { title: 'Transportbedrijf Aanmaken' },
        field: {
            name: { label: 'Naam' },
            email: { label: 'Email' },
        },
    },
    stock: {
        overview: {
            title: 'Voorraad',
            date: 'Datum',
        },
        field: {
            article: { label: 'Artikel' },
            startDate: { label: 'Startdatum' },
            endDate: { label: 'Einddatum' },
            inProduction: { label: 'In Productie' },
            toTransport: { label: 'Te Transporteren' },
            inTransport: { label: 'In Transport' },
            toDistribute: { label: 'Te Distribueren' },
        },
    },
    distributionOrder: {
        field: {
            deliveryDate: { label: 'Levering' },
        },
    },
    distributionOrderLine: {
        field: {
            article: { label: 'Artikel' },
            quantity: { label: 'Aantal' },
            deliveryIssue: {
                label: 'Leveringsprobleem',
                value: '{{count}} tot {{date}}',
            },
        },
    },
    transportOrder: {
        overview: {
            title: 'Transportorders',
            addTransportOrderButton: 'Transportorder Toevoegen',
            addTransportOrderModal: { title: 'Transportorder Toevoegen' },
            date: 'Datum',
            open: {
                totalCbm: '{{value}} Totaal',
                remainingCbm: '{{value}} Beschikbaar',
                transportButton: 'Transport',
                empty: 'Er is niks om te transporteren.',
            },
        },
        field: {
            transportCompany: { label: 'Transportbedrijf' },
            loadDate: { label: 'Laden' },
            unloadDate: { label: 'Lossen' },
            totalCbm: { label: 'Totale CBM' },
            notes: { label: 'Notities' },
        },
    },
    productionBatch: {
        field: {
            quantity: { label: 'Aantal' },
            readyDate: { label: 'Gereed' },
        },
    },
    thread: {
        actions: {
            reply: 'Reageren',
            resolve: 'Afvinken',
            resolveAndReply: 'Reageren en afvinken ongedaan maken',
            hideComments: 'Reacties verbergen',
            showComments: 'Toon {{count}} reacties',
        },
        field: {
            reply: { label: 'Reageer...' },
            message: { label: 'Nieuwe notitie...' },
            notes: {
                more: '{{count}} meer',
                none: 'Notitie toevoegen...',
            }
        },
        button: {
            showResolved: 'Afgevinkte notities tonen...',
            hideResolved: 'Afgevinkte notities verbergen...',
            hasUnresolved: 'Afgevinkte notities',
            hasResolved: 'Niet afgevinkte notities',
        }
    },
    transportOrderLine: {
        field: {
            quantity: { label: 'Aantal' },
            productionBatch: { label: 'Gereed' },
        },
    },
};
