import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { TransportOrder } from './TransportOrder';
import { ProductionBatch } from './ProductionBatch';

export class TransportOrderLine extends Model {
    static backendResourceName = 'transport_order_line';

    @observable id = null;
    @observable quantity = 0;

    relations() {
        return {
            transportOrder: TransportOrder,
            productionBatch: ProductionBatch,
        };
    }
}

export class TransportOrderLineStore extends Store {
    Model = TransportOrderLine;
    static backendResourceName = 'transport_order_line';
}
